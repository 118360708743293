<template>
  <div>
    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Rút tiền Napas</h6>
        </template>
        <div class="filter">
          <b-row>
            <b-col>
              <b-form-group label="Mã giao dịch">
                <b-form-input v-model="filter.code"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Bank code">
                <b-form-input v-model="filter.bank_code"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Mã tham chiếu">
                <b-form-input v-model="filter.ref_number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Trạng thái giao dịch">
                <b-form-select :options="orderStatus" v-model="filter.status"></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group label="Thời gian bắt đầu(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_from"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group label="Thời gian kết thúc(*)">
                <b-form-input
                    :id="`type-date`"
                    :type="`date`"
                    v-model="filter.date_to"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <div class="text-right">
                <b-button-group>
                  <b-button type="button" variant="primary" @click="search()"><i class="fa fa-search"></i> Tìm kiếm</b-button>
                  <b-button type="button" variant="success" @click="showModal()"><i class="fa fa-download"></i> Xuất Excel</b-button>
                </b-button-group>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="content">
          <b-summary :total="paginate.total" :current-page="paginate.page" :per-page="paginate.limit" :total-page="paginate.totalPage"></b-summary>
          <b-table :items="items" :fields = "fields" ref="table" striped hover responsive caption-top>
            <template #cell(index)="data">
              {{(currentPage - 1) * 25 + (data.index + 1)}}
            </template>
            <template #cell(created_at)="data">
              {{ formatDate(data.item.created_at) }}
            </template>
            <template #cell(payment_at)="data">
              {{ formatDate(data.item.payment_at) }}
            </template>
            <template #cell(amount)="data">
              {{ numberFormat(data.item.amount) }}
            </template>
            <template #cell(amount_bank)="data">
              {{ numberFormat(data.item.amount_bank) }}
            </template>
            <template #cell(status)="data">
              {{ transformStatus(data.item.status) }}
            </template>
          </b-table>

          <div class="mt-3">
            <b-pagination-nav
                v-model="currentPage"
                :link-gen="createLinkPage"
                :number-of-pages="paginate.totalPage"
                use-router
                first-number
                last-number
                align="right"
            ></b-pagination-nav>
          </div>
        </div>
      </b-card>
    </b-card-group>

    <export-modal :modalId="exportId" @exportData="exportData"></export-modal>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import Notifications from "vue-notification";
import Common from "@/core/mixins/common";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Vue from "vue";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import ExportModal from "@/view/pages/components/ExportModal.vue";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
// import router from "@/router";
import BSummary from "@/view/pages/components/Summary.vue";

Vue.use(Notifications);

const ReconcileRepository = RepositoryFactory.get("reconcile");
export default {
  components: { BSummary, AlertDialogue, ExportModal },
  mixins: [Common],
  data() {
    return {
      exportId: "export_napas_cash_out_reconcile",
      items: null,
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        status: null,
        code: null,
        bank_code: null,
        ref_number: null
      },
      orderStatus: [
        { value: "", text: "Tất cả" },
        { value: "0", text: "PENDING" },
        { value: "1", text: "SUCCESS" },
        { value: "-1", text: "FAIL" },
        { value: "-2", text: "CANCELED" },
        { value: "2", text: "REVERSED" },
      ],
      fields: [
        {
          key: "index",
          label: "Index"
        },
        {
          key: "code",
          label: "Mã giao dịch"
        },
        {
          key: "amount",
          label: "Số tiền"
        },
        {
          key: "amount_bank",
          label: "Số tiền ngân hàng"
        },
        {
          key: "bank_code",
          label: "Ngân hàng"
        },
        {
          key: "status",
          label: "Trạng thái ví"
        },
        {
          key: "bank_status",
          label: "Trạng thái ngân hàng"
        },
        {
          key: "ref_number",
          label: "Mã tham chiếu ngân hàng"
        },
        {
          key: "code_bank",
          label: "Mã yêu cầu"
        },
        {
          key: "payment_at",
          label: "Ngày thanh toán",
        },
        {
          key: "fee_expense",
          label: "Phí trả bank",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Đối soát rút tiền Napas", route: "napas-cashout" },
    ]);
  },
  methods: {
    search() {
      this.filter.page = 1;
      // router.push({ path: "/reconcile/napas-cashout", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getTransactions();
    },
    async getTransactions()
    {
      this.$bus.$emit("show-loading", true);
      this.items = [];
      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await ReconcileRepository.getNapasCashOut(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code) {
        this.notifyAlert("success", response.data.message);
        return true;
      }

      this.items = body.data.data;
      this.paginate.totalPage = body.data.last_page;
      this.paginate.total = body.data.total;
      this.paginate.page = body.data.current_page;
      this.paginate.limit = body.data.per_page;
    },
    showModal() {
      this.$bvModal.show(this.exportId);
    },
    async exportData(description = null) {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.export_desc = description
      this.$bvModal.hide(this.exportId);

      let response = await ReconcileRepository.exportNapasCashOut(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    convert(params) {
      Object.keys(params).forEach(key => {
        if (params[key] === null || params[key] == '') {
          delete params[key];
        }
      });
      return params;
    },
    transformStatus(status)
    {
      if (status === 0) {
        return "PENDING";
      }

      if (status === 1) {
        return "SUCCESS";
      }

      if (status === -1) {
        return "FAIL";
      }

      if (status === -2) {
        return "CANCEL";
      }

      if (status === 2) {
        return "REVERSE";
      }

      return "FAIL";
    }
  },
  created() {
    this.getTransactions();
  },
  watch: {
    currentPage(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getTransactions();
      }
    },
  },
};
</script>
